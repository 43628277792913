import { Auth } from "aws-amplify";
import wlConfig from "../../wlConfig";

/* global window, Office, console */

export async function fetchAndConfigureTenant() {
  try {
    const tenantId = await getTenantId();
    console.log(tenantId)
    if (tenantId) {
      const response = await fetch(`${wlConfig.configurationEndpoint}/tenant/${tenantId}`);
      const config = await response.json();
      console.log(config, "tenant config from api")
      var authObj = {
        Auth: {
          identityPoolId: config.data.identityPoolId,
          region: wlConfig.region,
          userPoolId: wlConfig.userPoolId,
          userPoolWebClientId: config.data.clientId,
        },
        API: {
          endpoints: [
            {
              name: "wl-logostore-tester",
              // endpoint: "https://logo-api.sandigital.uk",
              endpoint: wlConfig.apiEndpoint,
            },
          ],
        },
        oauth: {
          domain: wlConfig.cognitoDomain,
          scope: wlConfig.scope,
          redirectSignIn: constructAbsoluteUrl("/login"),
          redirectSignOut: constructAbsoluteUrl("/login"),
          responseType: wlConfig.responseType,
          clientId: config.data.clientId,
        }
      };
      console.log('configuring amplify auth', authObj)
      Auth.configure(authObj);
    return config;
  }
  else {
    throw new Error("Tenant ID is not available");
  }
  } catch (error) {
    console.error('Failed to fetch Amplify configuration:', error);
    throw error;
  }
}

export async function getTenantId() {
  if (window.location.hostname !== "localhost") {
    let addInSubdomain = window.location.hostname.split(".")[0];
    let tenantId = addInSubdomain.split("-");
    return tenantId[tenantId.length - 1];
  } else {
    return 'af7aea5c879b233f' //stg wl
    // return "0b879b155ca5745"; //stg kearney
  }
  // return "wl1"
}

export function constructAbsoluteUrl(endpoint) {
  const url = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : "") + endpoint;
  return url;
}

const getOpenSamlUrl = (config) => {
  return `https://${wlConfig.cognitoDomain}/oauth2/authorize?identity_provider=${
    config.data.idpProviderName
  }&response_type=CODE&client_id=${config.data.clientId}&redirect_uri=${
    constructAbsoluteUrl("/login.html")
  }&scope=${wlConfig.scope.join(" ")}`;
};

