import React from 'react'

const WlLogo = () => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 187 24" enableBackground="new 0 0 187 24" space="preserve">
      <g id="Layer_1">
        <g>
          <path fill="#087C82" d="M34.896,17.891V1.179h2.865v16.712H34.896z" />
          <path fill="#087C82" d="M45.166,5.62c0.875,0,1.695,0.164,2.459,0.49c0.764,0.326,1.433,0.776,2.006,1.349
			c0.573,0.573,1.022,1.241,1.349,2.005s0.49,1.584,0.49,2.459c0,0.859-0.163,1.671-0.49,2.435
			c-0.326,0.764-0.776,1.433-1.349,2.006c-0.573,0.573-1.241,1.023-2.006,1.349c-0.764,0.326-1.584,0.49-2.459,0.49
			c-0.875,0-1.695-0.164-2.459-0.49c-0.764-0.326-1.432-0.776-2.005-1.349c-0.573-0.573-1.023-1.241-1.349-2.006
			c-0.327-0.764-0.49-1.575-0.49-2.435c0-0.875,0.163-1.695,0.49-2.459c0.326-0.764,0.776-1.432,1.349-2.005
			c0.573-0.573,1.241-1.023,2.005-1.349C43.471,5.783,44.29,5.62,45.166,5.62z M45.166,8.485c-0.955,0-1.767,0.334-2.435,1.003
			c-0.668,0.668-1.003,1.48-1.003,2.435c0,0.939,0.334,1.743,1.003,2.411c0.318,0.334,0.688,0.585,1.11,0.752
			c0.422,0.167,0.863,0.251,1.325,0.251c0.462,0,0.899-0.084,1.313-0.251c0.414-0.167,0.788-0.418,1.122-0.752
			c0.668-0.669,1.003-1.472,1.003-2.411c0-0.955-0.334-1.767-1.003-2.435C46.9,8.819,46.089,8.485,45.166,8.485z"/>
          <path fill="#087C82" d="M64.688,16.697c0,0.859-0.163,1.671-0.49,2.435c-0.326,0.764-0.776,1.433-1.349,2.005
			c-0.573,0.573-1.241,1.027-2.006,1.361C60.08,22.833,59.26,23,58.385,23c-0.716,0-1.393-0.111-2.029-0.334
			c-0.637-0.223-1.226-0.542-1.767-0.955l2.077-2.053c0.509,0.318,1.082,0.478,1.719,0.478c0.971,0,1.782-0.342,2.435-1.027
			c0.668-0.668,1.003-1.472,1.003-2.411v-4.799c0-0.923-0.334-1.735-1.003-2.435c-0.669-0.668-1.48-1.003-2.435-1.003
			c-0.923,0-1.735,0.334-2.435,1.003c-0.668,0.701-1.003,1.512-1.003,2.435c0,0.955,0.334,1.767,1.003,2.435
			c0.7,0.668,1.512,1.003,2.435,1.003c0.478,0,0.907-0.08,1.289-0.239l1.075,2.674c-0.716,0.286-1.489,0.43-2.316,0.43h-0.048
			c-0.859,0-1.671-0.164-2.435-0.49c-0.764-0.326-1.432-0.776-2.005-1.349c-0.573-0.573-1.027-1.241-1.361-2.006
			c-0.334-0.764-0.501-1.583-0.501-2.459c0-0.875,0.143-1.695,0.43-2.459c0.287-0.764,0.688-1.432,1.206-2.005
			c0.517-0.573,1.138-1.022,1.862-1.349c0.724-0.326,1.516-0.489,2.375-0.489c0.827,0,1.56,0.123,2.197,0.37
			c0.636,0.247,1.194,0.553,1.671,0.919V5.954h2.865V16.697z"/>
          <g>
            <path fill="#333D47" d="M108.885,16.208c-0.573,0.573-1.241,1.023-2.006,1.349c-0.764,0.326-1.584,0.49-2.459,0.49
				c-0.875,0-1.695-0.164-2.459-0.49c-0.764-0.326-1.432-0.776-2.005-1.349c-0.573-0.573-1.023-1.241-1.349-2.006
				c-0.327-0.764-0.49-1.575-0.49-2.435c0-0.875,0.163-1.695,0.49-2.459c0.326-0.764,0.776-1.432,1.349-2.005
				c0.573-0.573,1.241-1.023,2.005-1.349c0.764-0.326,1.584-0.49,2.459-0.49c0.875,0,1.695,0.164,2.459,0.49
				c0.764,0.326,1.433,0.776,2.006,1.349l-2.029,2.029c-0.669-0.669-1.48-1.003-2.435-1.003c-0.923,0-1.735,0.334-2.435,1.003
				c-0.668,0.668-1.003,1.48-1.003,2.435c0,0.939,0.334,1.743,1.003,2.411c0.334,0.334,0.708,0.585,1.122,0.752
				c0.414,0.167,0.851,0.251,1.313,0.251c0.971,0,1.782-0.334,2.435-1.003L108.885,16.208z"/>
            <path fill="#333D47" d="M110.215,17.736V1.024h2.865v16.712H110.215z" />
            <path fill="#333D47" d="M120.302,5.464c0.875,0,1.695,0.164,2.459,0.49c0.764,0.326,1.433,0.776,2.006,1.349
				c0.573,0.573,1.022,1.241,1.349,2.005s0.49,1.584,0.49,2.459c0,0.859-0.163,1.671-0.49,2.435
				c-0.326,0.764-0.776,1.433-1.349,2.006c-0.573,0.573-1.241,1.023-2.006,1.349c-0.764,0.326-1.584,0.49-2.459,0.49
				c-0.875,0-1.695-0.164-2.459-0.49c-0.764-0.326-1.432-0.776-2.005-1.349c-0.573-0.573-1.023-1.241-1.349-2.006
				c-0.327-0.764-0.49-1.575-0.49-2.435c0-0.875,0.163-1.695,0.49-2.459c0.326-0.764,0.776-1.432,1.349-2.005
				c0.573-0.573,1.241-1.023,2.005-1.349C118.607,5.628,119.427,5.464,120.302,5.464z M120.302,8.329
				c-0.955,0-1.767,0.334-2.435,1.003c-0.668,0.668-1.003,1.48-1.003,2.435c0,0.939,0.334,1.743,1.003,2.411
				c0.318,0.334,0.688,0.585,1.11,0.752c0.422,0.167,0.863,0.251,1.325,0.251c0.462,0,0.899-0.084,1.313-0.251
				c0.414-0.167,0.788-0.418,1.122-0.752c0.668-0.669,1.003-1.472,1.003-2.411c0-0.955-0.334-1.767-1.003-2.435
				C122.037,8.663,121.225,8.329,120.302,8.329z"/>
            <path fill="#333D47" d="M138.602,5.799v11.937h-2.865v-0.907c-0.43,0.366-0.915,0.665-1.456,0.895
				c-0.541,0.231-1.146,0.346-1.815,0.346c-0.78,0-1.488-0.148-2.125-0.442c-0.637-0.294-1.181-0.696-1.635-1.205
				c-0.454-0.509-0.804-1.106-1.05-1.791c-0.247-0.684-0.37-1.417-0.37-2.197V5.799h2.865v6.637c0,0.748,0.278,1.401,0.835,1.958
				c0.541,0.541,1.194,0.811,1.958,0.811c0.779,0,1.44-0.27,1.982-0.811c0.541-0.541,0.811-1.194,0.811-1.958V5.799H138.602z"/>
            <path fill="#333D47" d="M152.104,1v16.736h-2.865v-5.968c0-0.971-0.334-1.783-1.003-2.435c-0.668-0.669-1.472-1.003-2.411-1.003
				c-0.955,0-1.767,0.334-2.435,1.003c-0.668,0.668-1.003,1.48-1.003,2.435c0,0.939,0.334,1.743,1.003,2.411
				c0.318,0.334,0.688,0.585,1.11,0.752c0.422,0.167,0.863,0.251,1.325,0.251c0.462,0,0.883-0.08,1.265-0.239l1.027,2.554
				c-0.732,0.366-1.576,0.549-2.531,0.549c-0.875,0-1.683-0.164-2.423-0.49c-0.74-0.326-1.381-0.776-1.922-1.349
				c-0.542-0.573-0.963-1.241-1.265-2.006c-0.302-0.764-0.454-1.575-0.454-2.435c0-0.875,0.151-1.695,0.454-2.459
				c0.302-0.764,0.724-1.432,1.265-2.005c0.541-0.573,1.182-1.023,1.922-1.349c0.74-0.326,1.548-0.49,2.423-0.49
				c0.764,0,1.448,0.12,2.053,0.358c0.605,0.239,1.137,0.541,1.599,0.907V1H152.104z"/>
          </g>
          <g>
            <path id="Path_59_00000045620146144359034890000014274412280837542543_" fill="#087C82" d="M72.081,5.528
				c3.158,0.181,5.685,2.687,5.893,5.842c-1.629-0.085-4.043-0.489-4.709-1.151C72.6,9.557,72.179,7.15,72.081,5.528"/>
            <path id="Path_60_00000142881986549128070190000015311546356466873227_" fill="#087C82" d="M77.953,12.209
				c-0.181,3.157-2.687,5.684-5.843,5.892c0.084-1.628,0.49-4.041,1.153-4.707S76.33,12.307,77.953,12.209"/>
            <path id="Path_61_00000170239382423487577610000009798214265410451330_" fill="#087C82" d="M71.276,18.095
				c-3.158-0.181-5.685-2.687-5.893-5.842c1.629,0.085,4.041,0.489,4.707,1.153S71.176,16.473,71.276,18.095"/>
            <path id="Path_62_00000075877789827361928200000016436878037305390998_" fill="#087C82" d="M65.401,11.42
				c0.181-3.158,2.687-5.685,5.843-5.893c-0.085,1.629-0.489,4.043-1.153,4.707C69.428,10.898,67.023,11.32,65.401,11.42"/>
          </g>
          <path fill="#333D47" d="M98.991,17.182c-0.191-1.804-1.182-3.023-2.783-3.752c-0.308-0.14-0.685-0.421-0.775-0.72
			c-0.635-2.107-1.925-3.488-3.958-4.165c-0.267-0.089-0.517-0.44-0.646-0.73c-0.448-1.013-0.679-2.169-1.281-3.063
			c-1.924-2.857-4.653-4.066-7.956-3.383c-2.709,0.56-4.566,2.144-5.602,4.566c0.321,0.231,0.623,0.485,0.905,0.766
			c0.199,0.199,0.384,0.41,0.558,0.629c0.744-2.215,2.671-3.898,4.951-4.148c2.933-0.322,5.597,1.514,6.432,4.397
			c0.172,0.595,0.116,0.871-0.531,1.101c-2.271,0.806-3.531,2.511-3.807,4.979c-0.094,0.836,0.216,1.333,0.804,1.408
			c0.594,0.075,0.951-0.322,1.06-1.181c0.273-2.151,1.783-3.612,3.705-3.583c1.897,0.028,3.375,1.474,3.609,3.589
			c0.084,0.759,0.39,1.134,1.155,1.217c1.426,0.154,2.383,1.318,2.325,2.713c-0.059,1.401-1.104,2.466-2.551,2.48
			c-3.126,0.03-6.252,0.009-9.378,0.009c-3.023-0.001-6.047,0.05-9.068-0.015c-1.198-0.026-2.256-0.462-3.075-1.156
			c-0.456,0.086-0.927,0.13-1.412,0.13c-0.351,0-0.695-0.024-1.031-0.069c1.172,1.776,3.192,3.115,5.835,3.043
			c2.891-0.078,5.786-0.015,8.679-0.015c3.022,0,6.045,0,9.067,0c0.052,0,0.106,0.01,0.155-0.002
			c0.865-0.217,1.827-0.257,2.573-0.693C98.531,20.61,99.188,19.047,98.991,17.182z"/>
        </g>
      </g>
      <g id="Layer_2" display="none">
        <g display="inline">
          <path d="M34.896,17.891V1.179h2.865v16.712H34.896z" />
          <path d="M45.166,5.62c0.875,0,1.695,0.164,2.459,0.49c0.764,0.326,1.433,0.776,2.006,1.349c0.573,0.573,1.022,1.241,1.349,2.005
			s0.49,1.584,0.49,2.459c0,0.859-0.163,1.671-0.49,2.435c-0.326,0.764-0.776,1.433-1.349,2.006
			c-0.573,0.573-1.241,1.023-2.006,1.349c-0.764,0.326-1.584,0.49-2.459,0.49c-0.875,0-1.695-0.164-2.459-0.49
			c-0.764-0.326-1.432-0.776-2.005-1.349c-0.573-0.573-1.023-1.241-1.349-2.006c-0.327-0.764-0.49-1.575-0.49-2.435
			c0-0.875,0.163-1.695,0.49-2.459c0.326-0.764,0.776-1.432,1.349-2.005c0.573-0.573,1.241-1.023,2.005-1.349
			C43.471,5.783,44.29,5.62,45.166,5.62z M45.166,8.485c-0.955,0-1.767,0.334-2.435,1.003c-0.668,0.668-1.003,1.48-1.003,2.435
			c0,0.939,0.334,1.743,1.003,2.411c0.318,0.334,0.688,0.585,1.11,0.752c0.422,0.167,0.863,0.251,1.325,0.251
			c0.462,0,0.899-0.084,1.313-0.251c0.414-0.167,0.788-0.418,1.122-0.752c0.668-0.669,1.003-1.472,1.003-2.411
			c0-0.955-0.334-1.767-1.003-2.435C46.9,8.819,46.089,8.485,45.166,8.485z"/>
          <path d="M64.688,16.697c0,0.859-0.163,1.671-0.49,2.435c-0.326,0.764-0.776,1.433-1.349,2.005
			c-0.573,0.573-1.241,1.027-2.006,1.361C60.08,22.833,59.26,23,58.385,23c-0.716,0-1.393-0.111-2.029-0.334
			c-0.637-0.223-1.226-0.542-1.767-0.955l2.077-2.053c0.509,0.318,1.082,0.478,1.719,0.478c0.971,0,1.782-0.342,2.435-1.027
			c0.668-0.668,1.003-1.472,1.003-2.411v-4.799c0-0.923-0.334-1.735-1.003-2.435c-0.669-0.668-1.48-1.003-2.435-1.003
			c-0.923,0-1.735,0.334-2.435,1.003c-0.668,0.701-1.003,1.512-1.003,2.435c0,0.955,0.334,1.767,1.003,2.435
			c0.7,0.668,1.512,1.003,2.435,1.003c0.478,0,0.907-0.08,1.289-0.239l1.075,2.674c-0.716,0.286-1.489,0.43-2.316,0.43h-0.048
			c-0.859,0-1.671-0.164-2.435-0.49c-0.764-0.326-1.432-0.776-2.005-1.349c-0.573-0.573-1.027-1.241-1.361-2.006
			c-0.334-0.764-0.501-1.583-0.501-2.459c0-0.875,0.143-1.695,0.43-2.459c0.287-0.764,0.688-1.432,1.206-2.005
			c0.517-0.573,1.138-1.022,1.862-1.349c0.724-0.326,1.516-0.489,2.375-0.489c0.827,0,1.56,0.123,2.197,0.37
			c0.636,0.247,1.194,0.553,1.671,0.919V5.954h2.865V16.697z"/>
          <g>
            <path d="M108.885,16.208c-0.573,0.573-1.241,1.023-2.006,1.349c-0.764,0.326-1.584,0.49-2.459,0.49
				c-0.875,0-1.695-0.164-2.459-0.49c-0.764-0.326-1.432-0.776-2.005-1.349c-0.573-0.573-1.023-1.241-1.349-2.006
				c-0.327-0.764-0.49-1.575-0.49-2.435c0-0.875,0.163-1.695,0.49-2.459c0.326-0.764,0.776-1.432,1.349-2.005
				c0.573-0.573,1.241-1.023,2.005-1.349c0.764-0.326,1.584-0.49,2.459-0.49c0.875,0,1.695,0.164,2.459,0.49
				c0.764,0.326,1.433,0.776,2.006,1.349l-2.029,2.029c-0.669-0.669-1.48-1.003-2.435-1.003c-0.923,0-1.735,0.334-2.435,1.003
				c-0.668,0.668-1.003,1.48-1.003,2.435c0,0.939,0.334,1.743,1.003,2.411c0.334,0.334,0.708,0.585,1.122,0.752
				c0.414,0.167,0.851,0.251,1.313,0.251c0.971,0,1.782-0.334,2.435-1.003L108.885,16.208z"/>
            <path d="M110.215,17.736V1.024h2.865v16.712H110.215z" />
            <path d="M120.302,5.464c0.875,0,1.695,0.164,2.459,0.49c0.764,0.326,1.433,0.776,2.006,1.349
				c0.573,0.573,1.022,1.241,1.349,2.005s0.49,1.584,0.49,2.459c0,0.859-0.163,1.671-0.49,2.435
				c-0.326,0.764-0.776,1.433-1.349,2.006c-0.573,0.573-1.241,1.023-2.006,1.349c-0.764,0.326-1.584,0.49-2.459,0.49
				c-0.875,0-1.695-0.164-2.459-0.49c-0.764-0.326-1.432-0.776-2.005-1.349c-0.573-0.573-1.023-1.241-1.349-2.006
				c-0.327-0.764-0.49-1.575-0.49-2.435c0-0.875,0.163-1.695,0.49-2.459c0.326-0.764,0.776-1.432,1.349-2.005
				c0.573-0.573,1.241-1.023,2.005-1.349C118.607,5.628,119.427,5.464,120.302,5.464z M120.302,8.329
				c-0.955,0-1.767,0.334-2.435,1.003c-0.668,0.668-1.003,1.48-1.003,2.435c0,0.939,0.334,1.743,1.003,2.411
				c0.318,0.334,0.688,0.585,1.11,0.752c0.422,0.167,0.863,0.251,1.325,0.251c0.462,0,0.899-0.084,1.313-0.251
				c0.414-0.167,0.788-0.418,1.122-0.752c0.668-0.669,1.003-1.472,1.003-2.411c0-0.955-0.334-1.767-1.003-2.435
				C122.037,8.663,121.225,8.329,120.302,8.329z"/>
            <path d="M138.602,5.799v11.937h-2.865v-0.907c-0.43,0.366-0.915,0.665-1.456,0.895c-0.541,0.231-1.146,0.346-1.815,0.346
				c-0.78,0-1.488-0.148-2.125-0.442c-0.637-0.294-1.181-0.696-1.635-1.205c-0.454-0.509-0.804-1.106-1.05-1.791
				c-0.247-0.684-0.37-1.417-0.37-2.197V5.799h2.865v6.637c0,0.748,0.278,1.401,0.835,1.958c0.541,0.541,1.194,0.811,1.958,0.811
				c0.779,0,1.44-0.27,1.982-0.811c0.541-0.541,0.811-1.194,0.811-1.958V5.799H138.602z"/>
            <path d="M152.104,1v16.736h-2.865v-5.968c0-0.971-0.334-1.783-1.003-2.435c-0.668-0.669-1.472-1.003-2.411-1.003
				c-0.955,0-1.767,0.334-2.435,1.003c-0.668,0.668-1.003,1.48-1.003,2.435c0,0.939,0.334,1.743,1.003,2.411
				c0.318,0.334,0.688,0.585,1.11,0.752c0.422,0.167,0.863,0.251,1.325,0.251c0.462,0,0.883-0.08,1.265-0.239l1.027,2.554
				c-0.732,0.366-1.576,0.549-2.531,0.549c-0.875,0-1.683-0.164-2.423-0.49c-0.74-0.326-1.381-0.776-1.922-1.349
				c-0.542-0.573-0.963-1.241-1.265-2.006c-0.302-0.764-0.454-1.575-0.454-2.435c0-0.875,0.151-1.695,0.454-2.459
				c0.302-0.764,0.724-1.432,1.265-2.005c0.541-0.573,1.182-1.023,1.922-1.349c0.74-0.326,1.548-0.49,2.423-0.49
				c0.764,0,1.448,0.12,2.053,0.358c0.605,0.239,1.137,0.541,1.599,0.907V1H152.104z"/>
          </g>
          <g>
            <path id="Path_59_00000070079458569764355000000005324612329182044810_" d="M72.081,5.528c3.158,0.181,5.685,2.687,5.893,5.842
				c-1.629-0.085-4.043-0.489-4.709-1.151C72.6,9.557,72.179,7.15,72.081,5.528"/>
            <path id="Path_60_00000140008616835205351420000015780083165065736077_" d="M77.953,12.209c-0.181,3.157-2.687,5.684-5.843,5.892
				c0.084-1.628,0.49-4.041,1.153-4.707S76.33,12.307,77.953,12.209"/>
            <path id="Path_61_00000098202963848061153960000011911969557628075946_" d="M71.276,18.095c-3.158-0.181-5.685-2.687-5.893-5.842
				c1.629,0.085,4.041,0.489,4.707,1.153S71.176,16.473,71.276,18.095"/>
            <path id="Path_62_00000040535923351216219370000006923913271948722617_" d="M65.401,11.42c0.181-3.158,2.687-5.685,5.843-5.893
				c-0.085,1.629-0.489,4.043-1.153,4.707C69.428,10.898,67.023,11.32,65.401,11.42"/>
          </g>
          <path d="M98.991,17.182c-0.191-1.804-1.182-3.023-2.783-3.752c-0.308-0.14-0.685-0.421-0.775-0.72
			c-0.635-2.107-1.925-3.488-3.958-4.165c-0.267-0.089-0.517-0.44-0.646-0.73c-0.448-1.013-0.679-2.169-1.281-3.063
			c-1.924-2.857-4.653-4.066-7.956-3.383c-2.709,0.56-4.566,2.144-5.602,4.566c0.321,0.231,0.623,0.485,0.905,0.766
			c0.199,0.199,0.384,0.41,0.558,0.629c0.744-2.215,2.671-3.898,4.951-4.148c2.933-0.322,5.597,1.514,6.432,4.397
			c0.172,0.595,0.116,0.871-0.531,1.101c-2.271,0.806-3.531,2.511-3.807,4.979c-0.094,0.836,0.216,1.333,0.804,1.408
			c0.594,0.075,0.951-0.322,1.06-1.181c0.273-2.151,1.783-3.612,3.705-3.583c1.897,0.028,3.375,1.474,3.609,3.589
			c0.084,0.759,0.39,1.134,1.155,1.217c1.426,0.154,2.383,1.318,2.325,2.713c-0.059,1.401-1.104,2.466-2.551,2.48
			c-3.126,0.03-6.252,0.009-9.378,0.009c-3.023-0.001-6.047,0.05-9.068-0.015c-1.198-0.026-2.256-0.462-3.075-1.156
			c-0.456,0.086-0.927,0.13-1.412,0.13c-0.351,0-0.695-0.024-1.031-0.069c1.172,1.776,3.192,3.115,5.835,3.043
			c2.891-0.078,5.786-0.015,8.679-0.015c3.022,0,6.045,0,9.067,0c0.052,0,0.106,0.01,0.155-0.002
			c0.865-0.217,1.827-0.257,2.573-0.693C98.531,20.61,99.188,19.047,98.991,17.182z"/>
        </g>
      </g>
      <g id="Layer_3" display="none">
        <g display="inline">
          <path fill="#FFFFFF" d="M34.896,17.891V1.179h2.865v16.712H34.896z" />
          <path fill="#FFFFFF" d="M45.166,5.62c0.875,0,1.695,0.164,2.459,0.49c0.764,0.326,1.433,0.776,2.006,1.349
			c0.573,0.573,1.022,1.241,1.349,2.005s0.49,1.584,0.49,2.459c0,0.859-0.163,1.671-0.49,2.435
			c-0.326,0.764-0.776,1.433-1.349,2.006c-0.573,0.573-1.241,1.023-2.006,1.349c-0.764,0.326-1.584,0.49-2.459,0.49
			c-0.875,0-1.695-0.164-2.459-0.49c-0.764-0.326-1.432-0.776-2.005-1.349c-0.573-0.573-1.023-1.241-1.349-2.006
			c-0.327-0.764-0.49-1.575-0.49-2.435c0-0.875,0.163-1.695,0.49-2.459c0.326-0.764,0.776-1.432,1.349-2.005
			c0.573-0.573,1.241-1.023,2.005-1.349C43.471,5.783,44.29,5.62,45.166,5.62z M45.166,8.485c-0.955,0-1.767,0.334-2.435,1.003
			c-0.668,0.668-1.003,1.48-1.003,2.435c0,0.939,0.334,1.743,1.003,2.411c0.318,0.334,0.688,0.585,1.11,0.752
			c0.422,0.167,0.863,0.251,1.325,0.251c0.462,0,0.899-0.084,1.313-0.251c0.414-0.167,0.788-0.418,1.122-0.752
			c0.668-0.669,1.003-1.472,1.003-2.411c0-0.955-0.334-1.767-1.003-2.435C46.9,8.819,46.089,8.485,45.166,8.485z"/>
          <path fill="#FFFFFF" d="M64.688,16.697c0,0.859-0.163,1.671-0.49,2.435c-0.326,0.764-0.776,1.433-1.349,2.005
			c-0.573,0.573-1.241,1.027-2.006,1.361C60.08,22.833,59.26,23,58.385,23c-0.716,0-1.393-0.111-2.029-0.334
			c-0.637-0.223-1.226-0.542-1.767-0.955l2.077-2.053c0.509,0.318,1.082,0.478,1.719,0.478c0.971,0,1.782-0.342,2.435-1.027
			c0.668-0.668,1.003-1.472,1.003-2.411v-4.799c0-0.923-0.334-1.735-1.003-2.435c-0.669-0.668-1.48-1.003-2.435-1.003
			c-0.923,0-1.735,0.334-2.435,1.003c-0.668,0.701-1.003,1.512-1.003,2.435c0,0.955,0.334,1.767,1.003,2.435
			c0.7,0.668,1.512,1.003,2.435,1.003c0.478,0,0.907-0.08,1.289-0.239l1.075,2.674c-0.716,0.286-1.489,0.43-2.316,0.43h-0.048
			c-0.859,0-1.671-0.164-2.435-0.49c-0.764-0.326-1.432-0.776-2.005-1.349c-0.573-0.573-1.027-1.241-1.361-2.006
			c-0.334-0.764-0.501-1.583-0.501-2.459c0-0.875,0.143-1.695,0.43-2.459c0.287-0.764,0.688-1.432,1.206-2.005
			c0.517-0.573,1.138-1.022,1.862-1.349c0.724-0.326,1.516-0.489,2.375-0.489c0.827,0,1.56,0.123,2.197,0.37
			c0.636,0.247,1.194,0.553,1.671,0.919V5.954h2.865V16.697z"/>
          <g>
            <path fill="#FFFFFF" d="M108.885,16.208c-0.573,0.573-1.241,1.023-2.006,1.349c-0.764,0.326-1.584,0.49-2.459,0.49
				c-0.875,0-1.695-0.164-2.459-0.49c-0.764-0.326-1.432-0.776-2.005-1.349c-0.573-0.573-1.023-1.241-1.349-2.006
				c-0.327-0.764-0.49-1.575-0.49-2.435c0-0.875,0.163-1.695,0.49-2.459c0.326-0.764,0.776-1.432,1.349-2.005
				c0.573-0.573,1.241-1.023,2.005-1.349c0.764-0.326,1.584-0.49,2.459-0.49c0.875,0,1.695,0.164,2.459,0.49
				c0.764,0.326,1.433,0.776,2.006,1.349l-2.029,2.029c-0.669-0.669-1.48-1.003-2.435-1.003c-0.923,0-1.735,0.334-2.435,1.003
				c-0.668,0.668-1.003,1.48-1.003,2.435c0,0.939,0.334,1.743,1.003,2.411c0.334,0.334,0.708,0.585,1.122,0.752
				c0.414,0.167,0.851,0.251,1.313,0.251c0.971,0,1.782-0.334,2.435-1.003L108.885,16.208z"/>
            <path fill="#FFFFFF" d="M110.215,17.736V1.024h2.865v16.712H110.215z" />
            <path fill="#FFFFFF" d="M120.302,5.464c0.875,0,1.695,0.164,2.459,0.49c0.764,0.326,1.433,0.776,2.006,1.349
				c0.573,0.573,1.022,1.241,1.349,2.005s0.49,1.584,0.49,2.459c0,0.859-0.163,1.671-0.49,2.435
				c-0.326,0.764-0.776,1.433-1.349,2.006c-0.573,0.573-1.241,1.023-2.006,1.349c-0.764,0.326-1.584,0.49-2.459,0.49
				c-0.875,0-1.695-0.164-2.459-0.49c-0.764-0.326-1.432-0.776-2.005-1.349c-0.573-0.573-1.023-1.241-1.349-2.006
				c-0.327-0.764-0.49-1.575-0.49-2.435c0-0.875,0.163-1.695,0.49-2.459c0.326-0.764,0.776-1.432,1.349-2.005
				c0.573-0.573,1.241-1.023,2.005-1.349C118.607,5.628,119.427,5.464,120.302,5.464z M120.302,8.329
				c-0.955,0-1.767,0.334-2.435,1.003c-0.668,0.668-1.003,1.48-1.003,2.435c0,0.939,0.334,1.743,1.003,2.411
				c0.318,0.334,0.688,0.585,1.11,0.752c0.422,0.167,0.863,0.251,1.325,0.251c0.462,0,0.899-0.084,1.313-0.251
				c0.414-0.167,0.788-0.418,1.122-0.752c0.668-0.669,1.003-1.472,1.003-2.411c0-0.955-0.334-1.767-1.003-2.435
				C122.037,8.663,121.225,8.329,120.302,8.329z"/>
            <path fill="#FFFFFF" d="M138.602,5.799v11.937h-2.865v-0.907c-0.43,0.366-0.915,0.665-1.456,0.895
				c-0.541,0.231-1.146,0.346-1.815,0.346c-0.78,0-1.488-0.148-2.125-0.442c-0.637-0.294-1.181-0.696-1.635-1.205
				c-0.454-0.509-0.804-1.106-1.05-1.791c-0.247-0.684-0.37-1.417-0.37-2.197V5.799h2.865v6.637c0,0.748,0.278,1.401,0.835,1.958
				c0.541,0.541,1.194,0.811,1.958,0.811c0.779,0,1.44-0.27,1.982-0.811c0.541-0.541,0.811-1.194,0.811-1.958V5.799H138.602z"/>
            <path fill="#FFFFFF" d="M152.104,1v16.736h-2.865v-5.968c0-0.971-0.334-1.783-1.003-2.435c-0.668-0.669-1.472-1.003-2.411-1.003
				c-0.955,0-1.767,0.334-2.435,1.003c-0.668,0.668-1.003,1.48-1.003,2.435c0,0.939,0.334,1.743,1.003,2.411
				c0.318,0.334,0.688,0.585,1.11,0.752c0.422,0.167,0.863,0.251,1.325,0.251c0.462,0,0.883-0.08,1.265-0.239l1.027,2.554
				c-0.732,0.366-1.576,0.549-2.531,0.549c-0.875,0-1.683-0.164-2.423-0.49c-0.74-0.326-1.381-0.776-1.922-1.349
				c-0.542-0.573-0.963-1.241-1.265-2.006c-0.302-0.764-0.454-1.575-0.454-2.435c0-0.875,0.151-1.695,0.454-2.459
				c0.302-0.764,0.724-1.432,1.265-2.005c0.541-0.573,1.182-1.023,1.922-1.349c0.74-0.326,1.548-0.49,2.423-0.49
				c0.764,0,1.448,0.12,2.053,0.358c0.605,0.239,1.137,0.541,1.599,0.907V1H152.104z"/>
          </g>
          <g>
            <path id="Path_59_00000004509979959246288490000017286541418623814043_" fill="#FFFFFF" d="M72.081,5.528
				c3.158,0.181,5.685,2.687,5.893,5.842c-1.629-0.085-4.043-0.489-4.709-1.151C72.6,9.557,72.179,7.15,72.081,5.528"/>
            <path id="Path_60_00000180350395013628476690000016508863649547728571_" fill="#FFFFFF" d="M77.953,12.209
				c-0.181,3.157-2.687,5.684-5.843,5.892c0.084-1.628,0.49-4.041,1.153-4.707S76.33,12.307,77.953,12.209"/>
            <path id="Path_61_00000054985062704254777340000013732449797003602085_" fill="#FFFFFF" d="M71.276,18.095
				c-3.158-0.181-5.685-2.687-5.893-5.842c1.629,0.085,4.041,0.489,4.707,1.153S71.176,16.473,71.276,18.095"/>
            <path id="Path_62_00000127762568936275516020000016174891051997687462_" fill="#FFFFFF" d="M65.401,11.42
				c0.181-3.158,2.687-5.685,5.843-5.893c-0.085,1.629-0.489,4.043-1.153,4.707C69.428,10.898,67.023,11.32,65.401,11.42"/>
          </g>
          <path fill="#FFFFFF" d="M98.991,17.182c-0.191-1.804-1.182-3.023-2.783-3.752c-0.308-0.14-0.685-0.421-0.775-0.72
			c-0.635-2.107-1.925-3.488-3.958-4.165c-0.267-0.089-0.517-0.44-0.646-0.73c-0.448-1.013-0.679-2.169-1.281-3.063
			c-1.924-2.857-4.653-4.066-7.956-3.383c-2.709,0.56-4.566,2.144-5.602,4.566c0.321,0.231,0.623,0.485,0.905,0.766
			c0.199,0.199,0.384,0.41,0.558,0.629c0.744-2.215,2.671-3.898,4.951-4.148c2.933-0.322,5.597,1.514,6.432,4.397
			c0.172,0.595,0.116,0.871-0.531,1.101c-2.271,0.806-3.531,2.511-3.807,4.979c-0.094,0.836,0.216,1.333,0.804,1.408
			c0.594,0.075,0.951-0.322,1.06-1.181c0.273-2.151,1.783-3.612,3.705-3.583c1.897,0.028,3.375,1.474,3.609,3.589
			c0.084,0.759,0.39,1.134,1.155,1.217c1.426,0.154,2.383,1.318,2.325,2.713c-0.059,1.401-1.104,2.466-2.551,2.48
			c-3.126,0.03-6.252,0.009-9.378,0.009c-3.023-0.001-6.047,0.05-9.068-0.015c-1.198-0.026-2.256-0.462-3.075-1.156
			c-0.456,0.086-0.927,0.13-1.412,0.13c-0.351,0-0.695-0.024-1.031-0.069c1.172,1.776,3.192,3.115,5.835,3.043
			c2.891-0.078,5.786-0.015,8.679-0.015c3.022,0,6.045,0,9.067,0c0.052,0,0.106,0.01,0.155-0.002
			c0.865-0.217,1.827-0.257,2.573-0.693C98.531,20.61,99.188,19.047,98.991,17.182z"/>
        </g>
      </g>
    </svg>

  )
}

export default WlLogo